import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/lamborghini/lamborghini-INDEX-1.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/desktop/lamborghini/lamborghini-INDEX-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/desktop/lamborghini/lamborghini-SRP-desktop.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/desktop/lamborghini/lamborghini-VDP-desktop.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/lamborghini/lamborghini-INDEX-mobile-1.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/mobile/lamborghini/lamborghini-INDEX-mobile-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/mobile/lamborghini/lamborghini-SRP-mobile.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/mobile/lamborghini/lamborghini-VDP-mobile.jpg',
  },
];

const Lamborghini = () => (
  <>
      <ProjectPageInfo
        title="Lamborghini Motors"
        icon="bi bi-geo-fill"
        location="USA Retail Dealerships"
        liveUrl="https://www.lamborghiniboston.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Lamborghini;
