import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/ineos/index-desktop.jpg',
  },
  {
    id: 2, name: 'SRP', url: '/images/desktop/ineos/srp-desktop.jpg',
  },
  {
    id: 3, name: 'VDP', url: '/images/desktop/ineos/vdp-desktop.jpg',
  },
  {
    id: 4, name: 'SERVICE', url: '/images/desktop/ineos/service-desktop.jpg',
  },
  {
    id: 5, name: 'MODEL', url: '/images/desktop/ineos/model-desktop.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/ineos/index-mobile.jpg',
  },
  {
    id: 2, name: 'GETTING-STARTED', url: '/images/mobile/ineos/vdp-mobile.jpg',
  },
  {
    id: 3, name: 'MEMBERS', url: '/images/mobile/ineos/srp-mobile.jpg',
  },
  {
    id: 4, name: 'HOW-IT-WORKS', url: '/images/mobile/ineos/service-mobile.jpg',
  },
  {
    id: 5, name: 'HOW-IT-WORKS', url: '/images/mobile/ineos/model-mobile.jpg',
  }
];

const Ineos = () => (
  <>
      <ProjectPageInfo
        title="Ineos Grenadier"
        icon="bi bi-geo-fill"
        location="Lake Bluff, IL"
        liveUrl="https://www.knauzineosgrenadier.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Ineos;

