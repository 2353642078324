import React from 'react';

import './TextAnimation.css';
 
const TextAnimation = () => {
  return (
    <div className="typing-slider">
      <p>Design</p>
      <p>Explore</p>
      <p>Learn</p>
    </div>
  );
}

export default TextAnimation;
