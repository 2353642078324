import React from 'react';

const ScoutingReportInfo = (props) => {
  return (
    <div className="col-sm-4 col-12 d-flex align-items-center flex-column">
      <div className="scouting-image">
        <img alt={props.imageAlt} src={props.imageUrl} width={props.imageWidth}></img>
      </div>
        <h2>{props.title}</h2>
        <h3 className="my-3">{props.tagline}</h3>
        <div className="scouting-description">
          <ul className="skills-list text-start">
          <h4 className="mt-4 mb-3 text-primary">{props.listTitleOne}:</h4>
            {props.listOne.map((scoutingReport) => {
                return (
                  <li className="mb-1">{scoutingReport}</li>
                );
              })}
          </ul>
          <ul className="tools-list text-start">
          <h4 className="mt-4 mb-3 text-primary">{props.listTitleTwo}:</h4>
            {props.listTwo.map((scoutingReport) => {
                return (
                  <li className="mb-1">{scoutingReport}</li>
                );
              })}
          </ul>
        </div>
    </div>
  );
};

export default ScoutingReportInfo;
