import React from 'react';

const AboutMe = () => {
  return (
    <section className="about-me bg-secondary">
      <div className="container row mx-auto text-center py-5">
        <div className="col-10 mx-auto d-flex justify-content-center flex-column">
          <h2 className="pb-3">Hello there and welcome to my portfolio.</h2>
          <h5 className="fw-normal">During the past nine years of my career, I’ve developed industry-leading user-friendly websites for some of the most demanding clients in the automotive space. This requires striking a balance between great design, prioritizing client business goals and delivering a product that puts user experience at the forefront. I am excited to share some of those projects with you and I hope you learn something about me today!</h5>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
