import React from 'react';
import ReactLoading from "react-loading";

import './LoadingScreen.css'

const Loading = () => {
  return (
    <div className="loading">
        <ReactLoading
          type={"bars"}
          color={"#0fd38b"}
          height={200}
          width={200}
        />
        <h5>Loading...</h5>
    </div>
  );
}

export default Loading;