import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/tactical/index-desktop.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/desktop/tactical/index-desktop2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/desktop/tactical/srp-desktop.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/desktop/tactical/vdp-desktop.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/tactical/index-mobile.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/mobile/tactical/index-mobile2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/mobile/tactical/srp-mobile.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/mobile/tactical/vdp-mobile.jpg',
  },
];

const Tactical = () => (
  <>
      <ProjectPageInfo
        title="Tactical Fleet"
        icon="bi bi-geo-fill"
        location="Dallas, TX"
        liveUrl="https://www.figma.com/file/LKE0lTPHojekZPy3y8jiY5/Tactical-Mockups?type=design&node-id=0%3A1&mode=design&t=qCpBJzNRMKWxm6XT-1"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Tactical;
