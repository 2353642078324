import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/avondale/avondale-INDEX.jpg',
  },
  {
    id: 2, name: 'GETTING-STARTED', url: '/images/desktop/avondale/avondale-GETTING-STARTED.jpg',
  },
  {
    id: 3, name: 'MEMBERS', url: '/images/desktop/avondale/avondale-MEMBERS.jpg',
  },
  {
    id: 4, name: 'HOW-IT-WORKS', url: '/images/desktop/avondale/avondale-HOW-IT-WORKS.jpg',
  },
  {
    id: 5, name: 'PLUS', url: '/images/desktop/avondale/avondale-PLUS.jpg',
  },
  {
    id: 6, name: 'PREMIUM', url: '/images/desktop/avondale/avondale-PREMIUM.jpg',
  },
  {
    id: 7, name: 'PRIME', url: '/images/desktop/avondale/avondale-PRIME.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/avondale/avondale-INDEX-mobile.jpg',
  },
  {
    id: 2, name: 'GETTING-STARTED', url: '/images/mobile/avondale/avondale-GETTING-STARTED-mobile.jpg',
  },
  {
    id: 3, name: 'MEMBERS', url: '/images/mobile/avondale/avondale-MEMBERS-STORIES-mobile.jpg',
  },
  {
    id: 4, name: 'HOW-IT-WORKS', url: '/images/mobile/avondale/avondale-HOW-IT-WORKS-mobile.jpg',
  },
  {
    id: 5, name: 'PLUS', url: '/images/mobile/avondale/avondale-PLUS-mobile.jpg',
  },
  {
    id: 6, name: 'PREMIUM', url: '/images/mobile/avondale/avondale-PREMIUM-mobile.jpg',
  },
  {
    id: 7, name: 'PRIME', url: '/images/mobile/avondale/avondale-PRIME-mobile.jpg',
  },
];

const Avondale = () => (
  <>
      <ProjectPageInfo
        title="Avondale Select"
        icon="bi bi-geo-fill"
        location="Dallas, TX"
        liveUrl="https://www.avondale.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Avondale;
