import React from 'react';
import './LogosListInfo.css'

const LogosListInfo = props => {
  return (
    <div className="col-sm-4 col-12 p-3 my-3">
      <div className="logo-image" style={{ backgroundImage: `url(${props.imgUrl})`, backgroundPosition: 'center' }}/>
    	<h3 className="logo-heading text-center mt-3">{props.name}</h3>
    </div>
  )
}

export default LogosListInfo