import React from 'react';
import RecentWorkInfo from '../components/RecentWork/components/RecentWorkInfo/RecentWorkInfo.js';
import projectsPage from '../json/projectsPage.json';

import '../components/RecentWork/RecentWork.css'

const ProjectsPage = () => (
  <section className="recent-work bg-secondary">
    <div className="container-xl mx-auto text-left py-5">
      <h1 className="mb-5">Web Design Projects</h1>
      <div className="grid-container">
        {Object.keys(projectsPage).map((item) => (
          <RecentWorkInfo
            imageUrl={projectsPage[item].imageUrl}
            imageAlt={projectsPage[item].imageAlt}
            title={projectsPage[item].title}
            icon={projectsPage[item].icon}
            location={projectsPage[item].location}
            liveUrl={projectsPage[item].liveUrl}
            designUrl={projectsPage[item].designUrl}
          />
        ))}
      </div>
    </div>
  </section>
);

export default ProjectsPage;
