import React from 'react';
import DesignCarouselDesktop from '../DesignCarousel/DesignCarouselDesktop';
import DesignCarouselMobile from '../DesignCarousel/DesignCarouselMobile';

import './ProjectPageInfo.css'

const ProjectPageInfo = (props) => (
  <div className="page-content">
    <section className="project-page">
      <div className="heading-wrapper bg-secondary pt-3 pb-4">
        <div className="container-xl">
          <h1 className="pt-2 pb-0">{props.title}</h1>
            <div className="dealer-info">
              <i className={props.icon}>{props.location}</i>
              <a className="ms-3 design-link" href={props.liveUrl} target="_BLANK" rel="noreferrer"> <i class="bi bi-box-arrow-up-right"></i>View Live Site</a>
            </div>
        </div>
      </div>
      <div className="container-xl">
        <DesignCarouselDesktop images={props.desktopImages} />
        <DesignCarouselMobile images={props.mobileImages} />
      </div>
    </section>
  </div>
);

export default ProjectPageInfo;
