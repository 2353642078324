import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/lithia/lithia-index.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/desktop/lithia/lithia-index-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/desktop/lithia/lithia-srp.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/desktop/lithia/lithia-vdp.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile//lithia/lithia-INDEX-mobile-1.jpg',
  },
  {
    id: 2, name: 'INDEX', url: '/images/mobile//lithia/lithia-INDEX-mobile-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/mobile//lithia/lithia-SRP-mobile.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/mobile//lithia/lithia-VDP-mobile.jpg',
  },
];

const Lithia = () => (
  <>
      <ProjectPageInfo
        title="Lithia Motors"
        icon="bi bi-geo-fill"
        location="USA Retail Dealerships"
        liveUrl="https://www.lithia.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Lithia;
