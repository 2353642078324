import React from 'react';
import { Link } from 'react-router-dom';


const RecentWorkInfo = (props) => {
  return (
   <div className="grid-item">
     <div className="grid-item-image-wrapper">
       <img alt={props.imageAlt} src={props.imageUrl}/>
     </div>
     <div className="grid-item-content-wrapper bg-white text-start">
       <h5>{props.title}</h5>
       <i className={props.icon}>{props.location}</i>
       <div className="design-links">
         <a className="live-link" href={props.liveUrl} target="_BLANK" rel="noreferrer">View Live Site</a>
         {props.designUrl !== 0 && <Link className="ms-3 design-link" to={props.designUrl}>View Designs</Link>}
       </div>
     </div>
   </div>
  )
}

export default RecentWorkInfo