import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/saltlake/saltlake-INDEX.jpg',
  },
  {
    id: 1, name: 'INDEX2', url: '/images/desktop/saltlake/saltlake-INDEX-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/desktop/saltlake/saltlake-SRP.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/desktop/saltlake/saltlake-VDP.jpg',
  },
  {
    id: 5, name: 'SHOWROOM', url: '/images/desktop/saltlake/saltlake-SHOWROOM.jpg',
  },
  {
    id: 6, name: 'SERVICE', url: '/images/desktop/saltlake/saltlake-SERVICE.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/saltlake/saltlake-INDEX-mobile.jpg', height: '4354px', mobileHeight: '2840px',
  },
  {
    id: 1, name: 'INDEX2', url: '/images/mobile/saltlake/saltlake-INDEX-mobile-2.jpg', height: '3893px', mobileHeight: '2540px',
  },
  {
    id: 3, name: 'SRP', url: '/images/mobile/saltlake/saltlake-SRP-mobile.jpg', height: '3725px', mobileHeight: '2425px',
  },
  {
    id: 4, name: 'VDP', url: '/images/mobile/saltlake/saltlake-VDP-mobile.jpg', height: '2226px', mobileHeight: '1305px',
  },
  {
    id: 5, name: 'SHOWROOM', url: '/images/mobile/saltlake/saltlake-SHOWROOM-mobile.jpg', height: '1495px', mobileHeight: '970px',
  },
  {
    id: 6, name: 'SERVICE', url: '/images/mobile/saltlake/saltlake-SERVICE-mobile.jpg', height: '4753px', mobileHeight: '3100px',
  },
];

const SaltLake = () => (
  <>
      <ProjectPageInfo
        title="Salt Lake City Motorcars"
        icon="bi bi-geo-fill"
        location="Salt Lake City, UT"
        liveUrl="https://www.saltlakecitymotorcars.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default SaltLake;

