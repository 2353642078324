import React from 'react';
import HomeHeroInfo from './components/HomeHeroInfo/HomeHeroInfo';

import './HomeHero.css';

const HomeHero = () => (
  <section className="home-hero position-relative overflow-hidden">
      <HomeHeroInfo />
  </section>
);

export default HomeHero;
