import React from 'react';

const HomeHeroAnimations = () => {
  return (
    <div className="animations">
      <ul className="squares">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <img className="photoshop" alt="Photoshop" src="/icons/photoshop.png"></img>
        <img className="react" alt="React" src="/icons/react.png"></img>
        <img className="indesign" alt="Indesign" src="/icons/indesign.png"></img>
        <img className="illustrator" alt="Illustrator" src="/icons/illustrator.png"></img>
      </ul>
  </div>
  );
}

export default HomeHeroAnimations;