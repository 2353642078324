import React from 'react';

/* COMPONENTS */
import HomeHero from '../components/HomeHero/HomeHero';
import AboutMe from '../components/AboutMe/AboutMe';
import ScoutingReport from '../components/ScoutingReport/ScoutingReport';
import RecentWork from '../components/RecentWork/RecentWork';
import ContactMe from '../components/ContactMe/ContactMe';

function Home() {
  return (
    <div className="page-content">
      <HomeHero />
      <AboutMe />
      <ScoutingReport />
      <RecentWork />
      <ContactMe />
    </div>
  );
};

export default Home;
