import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/genesis/genesis-INDEX.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/genesis/genesis-INDEX-mobile.jpg', height: '1056px', mobileHeight: '1056px',
  },
];

const Genesis = () => (
  <>
      <ProjectPageInfo
        title="Genesis Motors"
        icon="bi bi-geo-fill"
        location="USA Retail Dealerships"
        liveUrl="https://www.genesisofsouthbay.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Genesis;

