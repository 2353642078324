import React from 'react';

import HomeHeroAnimations from '../HomeHeroAnimations/HomeHeroAnimations';

const HomeHeroInfo = () => {
  return (
    <div className="container-xl mx-auto d-flex flex-column flex-md-row">
      <HomeHeroAnimations />
      <div className="hero-left p-5 col-md-6 col-sm-12">
        <div className="hero-content h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="hero-text">
            <div className="animated-title">
              <div className="text-top">
                <div>
                  <h1 className="fw-bold">Dave Meline</h1>
                  <h2 className="py-2">Designer & Front-end Developer</h2>
                </div>
              </div>
              <div className="text-bottom">
                <div>
                  <h4 className="fw-normal">I design and develop web solutions.<br />Currently in the automotive space.</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-images d-flex justify-content-around w-100 pt-4">
            <img className="laptop" alt="Laptop" src="/icons/programming.png"></img>
            <img className="teamwork" alt="Teamwork" src="/icons/brainstorming.png"></img>
            <img className="web-designer" alt="Web Designer" src="/icons/web-design.png"></img>
          </div>
        </div>
      </div>
      <div className="hero-right p-5 col-md-6 col-sm-12">
        <div className="hero-content h-100 d-flex flex-column justify-content-center align-items-center">
          <svg viewBox="0 0 50 50" width="50" height="50">
            <defs>
              <mask id="mask" x="0" y="0" width="100" height="49">
                <rect x="0.5" y="0.5" width="49" height="49" fill="#fff"/>
                <text x="20" textAnchor="middle" y="50" dy="0">D</text>
                <text x="25" id="ltrV" textAnchor="middle" y="50" dy="0">S</text>
                <text x="30" textAnchor="middle" y="50" dy="0">M</text>
              </mask>
            </defs>
              <rect x="0.5" y="0.5" width="49" height="49" mask="url(#mask)" fillOpacity="1" fill="#EBFAF4"/>
            </svg>
        </div>
      </div>
    </div>
  );
}

export default HomeHeroInfo;