import React from 'react';
import logos from '../json/logos.json';
import gopherLogos from '../json/gopherLogos.json';
import LogosListInfo from '../components/LogosListInfo/LogosListInfo';

const Logos = () => (
  <div className="page-content">
    <section className="logos-page">
      <div className="heading-wrapper bg-secondary py-5">
        <div className="container-xl">
          <h1 className="font-weight-bold">Logo Designs</h1>
          <h4>Here are some of the logos I have designed for different applications, departments, and events while working at Cox Automotive.</h4>
        </div>
      </div>
      <div className="logos-wrapper container-xl">
        <div className="logos-content d-flex flex-wrap p-3">
          {Object.keys(logos).map((item) => (
            <LogosListInfo
              class={logos[item].class}
              imgUrl={logos[item].imgUrl}
              name={logos[item].name}
            />
          ))}
        </div>
      </div>
      <div className="heading-wrapper bg-secondary py-5">
        <div className="container-xl">
          <h1 className="font-weight-bold">Gopher Designs</h1>
          <h4>Here are some of the themed Gopher logos I have designed for different holidays and events.</h4>
          <h4>Gopher is an in house built application that helps Project Managers and more complete their daily website tasks.</h4>
        </div>
      </div>
      <div className="logos-wrapper container-xl">
        <div className="logos-content d-flex flex-wrap p-3">
          {Object.keys(gopherLogos).map((item) => (
            <LogosListInfo
              class={gopherLogos[item].class}
              imgUrl={gopherLogos[item].imgUrl}
              name={gopherLogos[item].name}
            />
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default Logos;
