import React from 'react';
import RecentWorkInfo from './components/RecentWorkInfo/RecentWorkInfo';
import recentWork from '../../json/recentWork.json';
import { Link } from 'react-router-dom';

import './RecentWork.css'

const RecentWork = () => {
  return (
    <section className="recent-work bg-secondary">
      <div className="container-xl mx-auto text-center py-5">
        <h1 className="mb-5">Recent Work</h1>
        <div className="grid-container">
          {Object.keys(recentWork).map((item) => (
            <RecentWorkInfo
              imageUrl={recentWork[item].imageUrl}
              imageAlt={recentWork[item].imageAlt}
              title={recentWork[item].title}
              icon={recentWork[item].icon}
              location={recentWork[item].location}
              liveUrl={recentWork[item].liveUrl}
              designUrl={recentWork[item].designUrl}
            />
          ))}
        </div>
      <Link to="/projects" className="btn btn-primary my-5 w-20 mx-auto">View Projects</Link>
      </div>
    </section>
  );
}

export default RecentWork;
