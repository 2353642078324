import React from 'react';

import './Footer.css';

const Footer = () => (
  <footer className="footer d-flex justify-content-center align-items-center p-4 flex-column">
    <span>Designed and Built by me &copy; 2024</span>
    <span className="mt-3">Made from scratch in React with <i class="bi bi-arrow-through-heart-fill text-primary"></i></span>
  </footer>
);

export default Footer;
