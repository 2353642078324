import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

/* COMPONENTS */
import Footer from './components/Footer/Footer';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import Header from './components/Header/Header';

/* Nav Pages */
import Home from './pages/Home';
import Logos from './pages/Logos';
import ProjectsPage from './pages/ProjectsPage';

/* Design Pages */
import Avondale from './pages/Avondale';
import Fredbeans from './pages/Fredbeans';
import Genesis from './pages/Genesis';
import Ineos from './pages/Ineos';
import Lamborghini from './pages/Lamborghini';
import Lithia from './pages/Lithia';
import SaltLake from './pages/SaltLake';
import Tactical from './pages/Tactical';
import Ogara from './pages/Ogara';

// import SmoothScroll from './components/SmoothScroll/SmoothScroll';

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2200)
  }, [])

  return (
    <>
      {loading === false ? (
      <div className="body">
        <Header />
        {/* <SmoothScroll /> */}
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/projects' element={<ProjectsPage />} />
            <Route path='/logos' element={<Logos />} />
            <Route exact path="/avondale" element={<Avondale />} />
            <Route exact path="/fredbeans" element={<Fredbeans />} />
            <Route exact path="/genesis" element={<Genesis />} />
            <Route exact path="/ineos" element={<Ineos />} />
            <Route exact path="/lamborghini" element={<Lamborghini />} />
            <Route exact path="/lithia" element={<Lithia />} />
            <Route exact path="/salt-lake-city" element={<SaltLake />} />
            <Route exact path="/tactical" element={<Tactical />} />
            <Route exact path="/ogara-coach" element={<Ogara />} />
          </Routes>
        <Footer />
      </div>
      ) : (
      <LoadingScreen />
      )}
    </>
  )
}

export default App;
