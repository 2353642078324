import React, { useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import { HashLink as Link } from 'react-router-hash-link';

import './Header.css'

const Header = () => {

  // Header Hamburger Nav
  const addOpen = () => {
    const elem = document.querySelector(".menu");
    elem.classList.add("open");
  }

  const removeOpen = () => {
    const elem = document.querySelector(".menu");
    elem.classList.remove("open");
  }

  // Sticky Header
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  
  const isSticky = (e) => {
    const header = document.querySelector(".page-header");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
 };

  return (
    <header className="page-header w-100">
      <div className="logo-text">
        <div className="box">
          <div className="title">
            <span className="block"></span>
            <Link to="/" className="text-decoration-none"><h2>maybe it's...<span></span></h2></Link>
          </div>
        </div>
      </div>
      <Navbar className="ms-auto">
          <ul className="menu align-items-center mb-0">
            <li className="close-icon" onClick={() => removeOpen()}>&#10005;</li>
            <li className="menu-item" onClick={() => removeOpen()}>
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item" onClick={() => removeOpen()}>
              <Link to="/projects">Projects</Link>
            </li>
            <li className="menu-item" onClick={() => removeOpen()}>
              <Link to="/logos">Logos</Link>
            </li>
            <li className="menu-item" onClick={() => removeOpen()}>
              <Link to='/#contact'>Contact</Link>
            </li>
          </ul>
          <div className="menu-icon" onClick={() => addOpen()}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Hamburger_icon.svg/1024px-Hamburger_icon.svg.png" alt=""></img>
          </div>
        </Navbar>
    </header>
  );
}

export default Header;
