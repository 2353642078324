import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/ogara/ogara-INDEX.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/desktop/ogara/ogara-INDEX-2.jpg',
  },
  {
    id: 3, name: 'INDEX3', url: '/images/desktop/ogara/ogara-INDEX-3.jpg',
  },
  {
    id: 4, name: 'SRP', url: '/images/desktop/ogara/ogara-SRP.jpg',
  },
  {
    id: 5, name: 'VDP', url: '/images/desktop/ogara/ogara-VDP.jpg',
  },
  {
    id: 6, name: 'BRAND', url: '/images/desktop/ogara/ogara-BRAND.jpg',
  },
  {
    id: 7, name: 'MODEL', url: '/images/desktop/ogara/ogara-MODEL.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/ogara/ogara-INDEX-mobile.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/mobile/ogara/ogara-INDEX-2-mobile.jpg',
  },
  {
    id: 3, name: 'INDEX3', url: '/images/mobile/ogara/ogara-INDEX-3-mobile.jpg',
  },
  {
    id: 4, name: 'SRP', url: '/images/mobile/ogara/ogara-SRP-mobile.jpg',
  },
  {
    id: 5, name: 'VDP', url: '/images/mobile/ogara/ogara-VDP-mobile.jpg',
  },
  {
    id: 6, name: 'BRAND', url: '/images/mobile/ogara/ogara-BRAND-mobile.jpg',
  },
  {
    id: 7, name: 'MODEL', url: '/images/mobile/ogara/ogara-MODEL-mobile.jpg',
  },
];

const Ogara = () => (
  <>
      <ProjectPageInfo
        title="O'Gara Coach"
        icon="bi bi-geo-fill"
        location="Beverly Hills, CA"
        liveUrl="https://www.ogaracoach.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Ogara;

