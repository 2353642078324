import React from 'react';
import ProjectPageInfo from '../components/ProjectPageInfo/ProjectPageInfo';

const desktopImages = [
  {
    id: 1, name: 'INDEX', url: '/images/desktop/fredbeans/fredbeans-INDEX.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/desktop/fredbeans/fredbeans-INDEX-2.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/desktop/fredbeans/fredbeans-SRP.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/desktop/fredbeans/fredbeans-VDP.jpg',
  },
  {
    id: 5, name: 'SERVICE', url: '/images/desktop/fredbeans/fredbeans-SERVICE.jpg',
  },
  {
    id: 6, name: 'CAREERS', url: '/images/desktop/fredbeans/fredbeans-CAREERS.jpg',
  },
];

const mobileImages = [
  {
    id: 1, name: 'INDEX', url: '/images/mobile/fredbeans/fredbeans-INDEX-mobile.jpg',
  },
  {
    id: 2, name: 'INDEX2', url: '/images/mobile/fredbeans/fredbeans-INDEX-2-mobile.jpg',
  },
  {
    id: 3, name: 'SRP', url: '/images/mobile/fredbeans/fredbeans-SRP-mobile.jpg',
  },
  {
    id: 4, name: 'VDP', url: '/images/mobile/fredbeans/fredbeans-VDP-mobile.jpg',
  },
  {
    id: 5, name: 'SERVICE', url: '/images/mobile/fredbeans/fredbeans-SERVICE-mobile.jpg',
  },
  {
    id: 6, name: 'CAREERS', url: '/images/mobile/fredbeans/fredbeans-CAREERS-mobile.jpg',
  },
];

const Fredbeans = () => (
  <>
      <ProjectPageInfo
        title="Fred Beans Auto Group"
        icon="bi bi-geo-fill"
        location="Philadelphia, PA"
        liveUrl="https://www.fredbeans.com/"
        desktopImages={desktopImages}
        mobileImages={mobileImages}
      />
  </>
);

export default Fredbeans;
