import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedinIn, faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import TextAnimation from './components/TextAnimation/TextAnimation';

import './ContactMe.css';

const ContactMe = () => {
  return (
    <section id="contact" className="contact-me">
      <div className="container-xl row mx-auto text-center py-5">
        <h1 className="mb-5">Contact Me</h1>
        <div className="text-animation-wrapper mb-5">
          <h4>Inspired To</h4>
          <TextAnimation />
        </div>
        <div className="contact-icons">
          <a href="https://www.linkedin.com/in/david-meline/" target="_BLANK">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a href="mailto:dmeline199@gmail.com" target="_BLANK">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a href="https://soundcloud.com/dmelon" target="_BLANK">
            <FontAwesomeIcon icon={faSoundcloud} />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactMe;
