import React from 'react';
import ScoutingReportInfo from './components/ScoutingReportInfo/ScoutingReportInfo';
import scoutingReport from '../../json/scoutingReport.json';

import './ScoutingReport.css'

const ScoutingReport = () => {
  return (
    <section className="scouting-report">
      <div className="container-xl row mx-auto text-center py-5">
        <h1 className="mb-5">Scouting Report</h1>
        {Object.keys(scoutingReport).map((item) => (
          <ScoutingReportInfo
            imageUrl={scoutingReport[item].imageUrl}
            imageAlt={scoutingReport[item].imageAlt}
            imageWidth={scoutingReport[item].imageWidth}
            title={scoutingReport[item].title}
            tagline={scoutingReport[item].tagline}
            listTitleOne={scoutingReport[item].listTitleOne}
            listOne={scoutingReport[item].listOne}
            listTitleTwo={scoutingReport[item].listTitleTwo}
            listTwo={scoutingReport[item].listTwo}
          />
        ))}
        <a href="/images/resume-final2.pdf" target="_BLANK" className="btn btn-primary my-5 w-20 mx-auto">View Resume</a>
      </div>
    </section>
  );
}

export default ScoutingReport;
